import React from 'react';
import axios from 'axios';
import humps from 'humps';
import { config } from 'process';

export interface ListAllProjectsResponse {
  projects: DashboardProjectDto[];
}

export interface DashboardProjectDto {
  projectId: string;
  name: string;
  gcpProjectId: string;
  fermatVersion: string;
  createdAt: Date;
  lastActive: Date;
  vmIpAddress: string;
  testDeploymentStatus: DeploymentStatus;
  prodDeploymentStatus: DeploymentStatus;
}

export interface FermatHealthCheckResponse {
  status: string;
  message: string;
}

export type DeploymentStatus = 
    | "NOT_DEPLOYED"
    | "DEPLOYMENT_IN_PROGRESS"
    | "DEPLOYMENT_SUCCESS"
    | "DEPLOYMENT_FAILURE"
    | "DEPLOYMENT_SUSPENDED"
    | "DEPLOYMENT_SUSPENSION_IN_PROGRESS"
    | "DEPLOYMENT_SUSPENSION_FAILURE"
    | "DEPLOYMENT_RESTORE_IN_PROGRESS"
    | "DEPLOYMENT_RESTORE_FAILURE";

export type Environment = "dev" | "prod";

const api = axios.create({
  baseURL: "https://api.swizzle-internal.com/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
  // @ts-ignore
  transformRequest: [(data) => data ? humps.decamelizeKeys(data) : data, ...axios.defaults.transformRequest],  
  // @ts-ignore
  transformResponse: [(data) => data ? humps.camelizeKeys(JSON.parse(data)) : data, ...axios.defaults.transformResponse],
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
);

const listAllProjects = async (): Promise<ListAllProjectsResponse> => {
  const resp = await api.get<ListAllProjectsResponse>("/admin/listProjects");
  return resp.data;
};

const restartTestEnvironment = async (projectId: string) => {
  return api.post(`/projects/${projectId}/restartTestEnv`);
};

const suspendProject = async (projectId: string) => {
  return api.post(`/projects/${projectId}/suspend`);
};

const restoreProject = async (projectId: string) => {
  return api.post(`/projects/${projectId}/restore`);
};

const getProject = async (projectId: string): Promise<DashboardProjectDto>  => {
  const resp = await api.get(`/admin/project/${projectId}`);
  return resp.data;
};

const getFermatHealth = async (projectId: string) => {
  console.log("Getting fermat health for project:", projectId);
  const resp = await api.get<FermatHealthCheckResponse>(`/admin/project/${projectId}/fermatHealthCheck`);
  return resp.data;
};

const setEnvironment = (env: Environment) => {
  api.defaults.baseURL = env === "dev" ? "https://api.swizzle-internal.com/api/v1" : "https://api.swizzle.co/api/v1"
}

export {
  listAllProjects,
  restartTestEnvironment,
  getProject,
  setEnvironment,
  suspendProject,
  restoreProject,
  getFermatHealth
};