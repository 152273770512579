import React, { useCallback, useEffect, useState } from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))
import { Environment } from './EulerClient';

interface EnvironmentDropdownProps {
    onSelect: (env: Environment) => void;
}

const EnvironmentDropdown: React.FC<EnvironmentDropdownProps> = ({ onSelect }) => {
    const [environment, setEnvironment] = useState<Environment>("dev");

    const handleSelect = useCallback((event) => {
      const env = event.target.value;
      setEnvironment(env);
      onSelect(env); // Call the passed onSelect function with the new environment
    }, [onSelect]);
  
    return (
      <select value={environment} onChange={handleSelect}>
        <option value="dev">Dev</option>
        <option value="prod">Prod</option>
      </select>
    );
};

export default EnvironmentDropdown;