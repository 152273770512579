import React, { useCallback, useEffect, useState } from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))
import { DashboardProjectDto, getFermatHealth, restartTestEnvironment, suspendProject, restoreProject } from './EulerClient';
import { useGlobalContext } from './GlobalContext';

interface ProjectDashboardProps {
    selectedProject: DashboardProjectDto;
    setSelectedProject: (project: DashboardProjectDto) => void;
}

const ProjectDashboard: React.FC<ProjectDashboardProps> = ({selectedProject, setSelectedProject}) => {
  const globalContext = useGlobalContext();
  
  const [restartingTestEnv, setRestartingTestEnv] = useState(false);
  const [restartTestEnvFailed, setRestartTestEnvFailed] = useState<boolean | undefined>();

  const [suspendingProject, setSuspendingProject] = useState(false);
  const [suspendingProjectFailed, setSuspendingProjectFailed] = useState<boolean | undefined>();

  const [restoringProject, setRestoringProject] = useState(false);
  const [restoringProjectFailed, setRestoringProjectFailed] = useState<boolean | undefined>();

  const [fermatHealthStatus, setFermatHealthStatus] = useState("???");
  const [fermatHealthMessage, setFermatHealthMessage] = useState("Unknown");

  useEffect(() => {
    const fetchAndUpdateFermatHealthStatus = async () => {
      try {
        const health = await getFermatHealth(selectedProject.projectId);
        setFermatHealthStatus(health.status);
        setFermatHealthMessage(health.message);

      } catch (e) {
        console.error('Error getting fermat health:', e)
      }
    };

    fetchAndUpdateFermatHealthStatus();
    const intervalId = setInterval(fetchAndUpdateFermatHealthStatus, 10_000);

    return () => {
      clearInterval(intervalId);
    }
  }, [selectedProject.projectId])

  const getSSH = useCallback(() => {
    if (globalContext.environment === 'dev') {
      return `ssh swizzle@${selectedProject.vmIpAddress} -p 2222 -i swizzle-clients-internal-key`;
    } else {
      return `ssh swizzle@${selectedProject.vmIpAddress} -p 2222 -i swizzle-clients-key`;
    }
  }, [globalContext.environment, selectedProject.vmIpAddress])

  const tryRestartTestEnvironment = useCallback(async () => {
    try {
      setRestartingTestEnv(true);
      await restartTestEnvironment(selectedProject.projectId);
      setRestartTestEnvFailed(false);
    } catch (error) {
      console.log("Error restarting test environment:", error)
      setRestartTestEnvFailed(true);
    } finally {
      setRestartingTestEnv(false);
    }
  }, [selectedProject.projectId]);

  const trySuspendingProject = useCallback(async () => {
    try {
      setSuspendingProject(true);
      await suspendProject(selectedProject.projectId);
      setSuspendingProjectFailed(false);
    } catch (error) {
      console.log("Error suspending project:", error)
      setSuspendingProjectFailed(true);
    } finally {
      setSuspendingProject(false);
    }
  }, [selectedProject.projectId]);

  const tryRestoringProject = useCallback(async () => {
    try {
      setRestoringProject(true);
      await restoreProject(selectedProject.projectId);
      setRestoringProjectFailed(false);
    } catch (error) {
      console.log("Error restoring project:", error)
      setRestoringProjectFailed(true);
    } finally {
      setRestoringProject(false);
    }
  }, [selectedProject.projectId]);

  const canRestartTestEnv = useCallback(() => {
    return !restartingTestEnv && !suspendingProject 
      && selectedProject.testDeploymentStatus !== "DEPLOYMENT_IN_PROGRESS" 
      && selectedProject.testDeploymentStatus !== "DEPLOYMENT_SUSPENSION_IN_PROGRESS"
      && selectedProject.testDeploymentStatus!== "DEPLOYMENT_RESTORE_IN_PROGRESS";
  }, [restartingTestEnv, selectedProject.testDeploymentStatus, suspendingProject])

  const canSuspendProject = useCallback(() => {
    return !suspendingProject && !restartingTestEnv && !restoringProject && selectedProject.testDeploymentStatus === "DEPLOYMENT_SUCCESS";
  }, [restartingTestEnv, selectedProject.testDeploymentStatus, suspendingProject, restoringProject])

  const canRestoreProject = useCallback(() => {
    return !suspendingProject && !restartingTestEnv && !restoringProject && selectedProject.testDeploymentStatus === "DEPLOYMENT_SUSPENDED";
  }, [restartingTestEnv, selectedProject.testDeploymentStatus, suspendingProject, restoringProject])

  return (
    <div className="flex flex-col items-start">
      <button onClick={() => setSelectedProject(undefined)}>Back</button>
      <div className="grid grid-cols-2 auto-cols-max gap-2">
        <span className="font-bold">ID</span><button onClick={() => navigator.clipboard.writeText(selectedProject.projectId)}>{selectedProject.projectId}</button>
        <span className="font-bold">Name</span><span>{selectedProject.name}</span>
        <span className="font-bold">GCP Name</span><span>{selectedProject.gcpProjectId}</span>
        <span className="font-bold">Test Deployment Status</span><span>{selectedProject.testDeploymentStatus}</span>          
        <span className="font-bold">Prod Deployment Status</span><span>{selectedProject.prodDeploymentStatus}</span>          
        <span className="font-bold">IpV4</span><span>{selectedProject.vmIpAddress}</span>          
        <span className="font-bold">Fermat Version</span><span>{selectedProject.fermatVersion}</span>          
      </div> 
      <div className="flex flex-col items-start my-2">
        <button 
          onClick={() => navigator.clipboard.writeText(getSSH())}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Copy SSH
        </button>
        <div className="flex flex-row space-x-4 items-center mt-2">
          <button 
            className={`${!canRestartTestEnv() ? 'opacity-50 cursor-not-allowed' : ''}`} 
            onClick={() => tryRestartTestEnvironment()}
            disabled={!canRestartTestEnv()}
          >
              Restart Test Environment
          </button>
          {restartingTestEnv && <span>Restarting...</span>}
          {!restartingTestEnv && restartTestEnvFailed === true && <span className="text-red-500">Restart failed</span>}
          {!restartingTestEnv && restartTestEnvFailed === false && <span className='text-green-500'>Restart succeeded!</span>}
        </div>
        <div className="flex flex-row space-x-4 items-center mt-2">
          <button 
            className={`${!canSuspendProject() ? 'opacity-50 cursor-not-allowed' : ''}`} 
            onClick={() => trySuspendingProject()}
            disabled={!canSuspendProject()}
          >
              Suspend Project
          </button>
          {suspendingProject && <span>Suspending...</span>}
          {!suspendingProject && suspendingProjectFailed === true && <span className="text-red-500">Suspension failed</span>}
          {!suspendingProject && suspendingProjectFailed === false && <span className='text-green-500'>Suspension started!</span>}
        </div>
        <div className="flex flex-row space-x-4 items-center mt-2">
          <button 
            className={`${!canRestoreProject() ? 'opacity-50 cursor-not-allowed' : ''}`} 
            onClick={() => tryRestoringProject()}
            disabled={!canRestoreProject()}
          >
              Restore Project
          </button>
          {restoringProject && <span>Restoring...</span>}
          {!restoringProject && restoringProjectFailed === true && <span className="text-red-500">Restore failed</span>}
          {!restoringProject && restoringProjectFailed === false && <span className='text-green-500'>Restore started!</span>}
        </div>
      </div>
      <span>Fermat ({fermatHealthStatus}): {fermatHealthMessage}</span>
    </div>
  );
};

export default ProjectDashboard;