import React from 'react';
import { Route as SwizzleRoute, Routes as SwizzleRoutes } from 'react-router-dom';
import SwizzleHomePage from './pages/SwizzleHomePage';
import SwizzlePrivateRoute from './SwizzlePrivateRoute';
import A_B_C from './pages/A_B_C';
import A_B_C_D from './pages/a/b/c/D';

const RouteList = () => {
  return (
    <SwizzleRoutes>
<SwizzleRoute path="/a/b/c/d" element={<A_B_C_D />} />
<SwizzleRoute path="/a/b/c" element={<A_B_C />} />
<SwizzleRoute path="/" element={<SwizzleHomePage />} />
</SwizzleRoutes>
  )
};

export default RouteList;
