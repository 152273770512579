import React, { useCallback, useEffect, useRef, useState } from "react";
import * as euler from "../components/EulerClient";
import Fuse from "fuse.js"
import _ from "lodash"
import ProjectDashboard from "../components/ProjectDashboard";
import EnvironmentDropdown from "../components/EnvironmentDropdown";
import { useGlobalContext } from "../components/GlobalContext";

//SwizzleHomePage is the home page (/) of your webapp
function SwizzleHomePage() {
  const [jwt, setJwt] = useState("");
  const [projects, setProjects] = useState<euler.DashboardProjectDto[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<euler.DashboardProjectDto[]>([]);
  const [projectQuery, setProjectQuery] = useState("");
  const [selectedProject, setSelectedProject] = useState<euler.DashboardProjectDto | undefined>();

  const globalContext = useGlobalContext();

  const fetchProjects = useCallback(() => {
    euler.listAllProjects().then((response) => {
      setProjects(response.projects);
    });
  }, []);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    localStorage.setItem("jwt", jwt);
    fetchProjects();
  }, [fetchProjects, jwt]);

  useEffect(() => {
    if (projectQuery === "") {
      setFilteredProjects(projects);
    } else {
      const fuse = new Fuse(projects, {keys: ["name", "gcpProjectId"], threshold: 0.3})
      const result = fuse.search(projectQuery);
      setFilteredProjects(result.map(project => project.item));
    }
  }, [projectQuery, projects])

  // TODO: This shouldn't be re-running every second like it is but for some reason the isEqual check
  // always returns false which causes the setState to occur which causes the useEffect to run again.
  // It's not a big deal because the net result is the same: the selectedProject gets updated once per second.
  useEffect(() => {
    let intervalId = null;

    const fetchAndUpdateProject = async () => {
      try {
        const updatedProject = await euler.getProject(selectedProject.projectId);
        setProjects(currentProjects => currentProjects.map(p => p.projectId === updatedProject.projectId ? updatedProject : p))

        if (!_.isEqual(selectedProject, updatedProject)) {
          setSelectedProject(updatedProject);
        }

      } catch (e) {
        console.error('Error fetching project info:', e)
      }
    };

    if (selectedProject) { 
      intervalId = setInterval(fetchAndUpdateProject, 1000);
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [selectedProject]);

  return (
    <div className="bg-gray-100 h-screen flex flex-col items-center">
      <div className="flex items-center my-2">
        <span>JWT:</span>
        <form onSubmit={handleSubmit}>
          <input
            className="mx-5 px-1"
            type="password"
            value={jwt}
            onChange={(e) => setJwt(e.target.value)}
            placeholder="Enter your secret JWT"
          />
        </form>
        <EnvironmentDropdown onSelect={env => {
          if (env !== globalContext.environment) {
            globalContext.setEnvironment(env);
            setProjects([]);
            setSelectedProject(undefined);
            setProjectQuery("");
            euler.setEnvironment(env);
          }
        }}/>  
      </div>
      
      {selectedProject ?
        <ProjectDashboard selectedProject={selectedProject} setSelectedProject={setSelectedProject}/>
      :
      <div>
        {projects.length > 0 && 
          <input 
            className="p-2" 
            type="search" 
            value={projectQuery} 
            name="query" 
            placeholder="Search..." 
            onChange={(e) => {
              setProjectQuery(e.target.value);
            }}
          />}
        <div className="grid grid-cols-2 auto-cols-max gap-2 w-96">
            {projects.length > 0 && <>
                <p className="col-span-1 font-bold">Name</p><span></span>
              </>
            }
            {filteredProjects.map((project, index) => 
              <React.Fragment key={project.projectId}>
                <p className="col-span-1">{project.name}</p>
                <button className="col-span-1 justify-self-end" onClick={() => setSelectedProject(project)}>Select</button>
              </React.Fragment>
            )}
          </div>
        </div>
      }
    </div>
    );
  }
  
export default SwizzleHomePage;