import React from "react";
import { AuthProvider } from 'react-auth-kit';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import RouteList from './RouteList';
import { GlobalContext, GlobalProvider } from "./components/GlobalContext";

function App() {
  return (
    <AuthProvider authType={'localstorage'} authName={'_auth'}>
      <GlobalProvider>
        <Router>
          <RouteList />
        </Router>
      </GlobalProvider>
    </AuthProvider>
  );
}

export default App;
