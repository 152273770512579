import React from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))

const A_B_C = () => {

    return (
        <div>
            {/* Your content here */}
        </div>
    );
};

export default A_B_C;