import React from 'react';
import api from '../../../../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))
import { useAuthUser } from 'react-auth-kit';

const D = () => {
    const auth = useAuthUser();
    return (
        <div>
            {/* Your content here */}
        </div>
    );
};

export default D;