import React, { FC, ReactNode, createContext, useContext, useState } from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))
import { Environment } from './EulerClient';

interface GlobalContextType {
  environment: Environment;
  setEnvironment: (env: Environment) => void; // Function to update the environment
}

interface GlobalContextProviderProps {
  children?: ReactNode;
}

export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalProvider:FC<GlobalContextProviderProps> = ({ children }) => {
  const [environment, setEnvironment] = useState<Environment>("dev");

  return (
    <GlobalContext.Provider value={{ environment, setEnvironment }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
}